/* eslint-disable @typescript-eslint/naming-convention */

export const environment = {
  production: true,
  isDebugMode: false,

  WORKSPACE: 'prod',
  URL_BASE: 'https://www.rappidexx.com.br',
  GOOGLE_MAPS_API_KEY: 'AIzaSyBIPt96wFHgfjuTMN9Zny9q_EfxY56lmrg',
  ONESIGNAL_APP_ID: '',
  JITSI_APP_ID: '',
};
