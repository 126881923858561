/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { ToastService } from 'src/app/utils/toastService';
import { TimelineService } from 'src/app/services/timeline/timeline.service';
import { USER, LOADING_ICON, TOKEN, HIDE_PULSE_SCHEDULES, PULSE_SCHEDULES } from 'src/main';
//import { format, utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';

@Component({
  selector: 'app-add-schedule',
  templateUrl: './add-schedule.page.html',
  styleUrls: ['./add-schedule.page.scss'],
})
export class AddSchedulePage implements OnInit {
  loading = false;
  loadingSpinner = false;
  postCodeAccepted = false;
  payment_early = false;
  on_holidays = false;

  headerTxt = 'Novo';
  btTxt = 'Salvar';
  btColor = 'success';

  data = {
    id: 0,
    title: '',
    weekday: '',
    initial_time: '00:00',
    final_time: '00:00',
    duration: 0,
    break_time: 0,
    type: '',
    professional_address: '',
    payment_early: false,
    on_holidays: false,
    services: []
  };

  selectedServices = [];
  sendServices = [];

  services = [];
  tempServices = [];

  addresses = [];

  user = {
    name: '',
    last_name: '',
    email: '',
    phone: '',
    profile_picture: '',
    professional: {
      attendance_radius: '',
      can_do_online_attendance: '',
      is_verified: '',
      register_number: '',
      addresses: [],
      services: [],
      categories: {
        name: ''
      },
      payment_plan_active: {
        status: 0
      }
    }
  };

  constructor(
    public toastService: ToastService,
    public loadingController: LoadingController,
    public modalController: ModalController,
    private serviceTimeline: TimelineService,
    public alertController: AlertController,
    private storage: Storage,
  ) { }

  ngOnInit() {
    this.loadPage();
  }

  loadPage() {
    this.storage.get(USER).then((user) => {
      this.user = user;
      this.services = this.user.professional.services;
      this.tempServices = this.services;
      this.addresses = [];
      this.user.professional.addresses.forEach(element => {
        if (element.name !== 'personal') {
          this.addresses.push(element);
        }
      });

      if (this.data.id > 0) {
        this.headerTxt = 'Atualizar';
        this.btTxt = 'Atualizar';
        this.btColor = 'primary';

        this.payment_early = this.data.payment_early;
        this.on_holidays = this.data.on_holidays;

        this.data.services.forEach(element => {
          this.selectedServices.push(element.service);
        });
        this.changeCategories();
        this.setPrice();
      } else {
        const date = new Date('2022-01-01T10:00:00Z');
        const date2 = new Date('2022-01-01T01:00:00Z');
        this.data.initial_time = format(date, 'HH:mm');
        this.data.final_time = format(date2, 'HH:mm');
      }
    });
  }

  async save() {
    if (this.data.title) {
      if (this.data.weekday) {
        if (this.data.initial_time) {
          if (this.data.final_time) {
            if (this.data.final_time <= '23:59' && this.data.initial_time <= '23:59' && this.data.final_time >= '00:00' && this.data.initial_time >= '00:00') {
              if (this.data.final_time > this.data.initial_time) {
                if (this.data.type) {
                  if (this.sendServices.length > 0) {
                    let exist = true;

                    this.sendServices.forEach(element => {
                      if (!element.price) {
                        exist = false;
                      }
                    });

                    if (exist) {
                      if (this.data.type === 'office') {
                        if (this.data.professional_address) {
                          this.send();
                        } else {
                          this.toastService.sendMessage(200, 'Selecione o local de atendimento');
                        }
                      } else if (this.data.type === 'home') {
                        if (this.user.professional.attendance_radius) {
                          this.send();
                        } else {
                          this.toastService.sendMessage(200, 'Você ainda não definiu um raio de atendimento');
                        }
                      }
                      else if (this.data.type === 'online') {
                        if (this.user.professional.can_do_online_attendance) {
                          this.send();
                        } else {
                          this.toastService.sendMessage(200, 'Você precisa de permissão para realizar atendimentos online');
                        }
                      }
                      else {
                        if (this.data.duration <= 0) {
                          this.toastService.sendMessage(200, 'Informe a duração do atendimento');
                          return;
                        }

                        this.send();
                      }
                    } else {
                      this.toastService.sendMessage(200, 'Informe o valor de cada especialidade');
                    }
                  } else {
                    this.toastService.sendMessage(200, 'Selecione ao menos uma especialidade');
                  }

                } else {
                  this.toastService.sendMessage(200, 'Selecione o tipo de atendimento');
                }
              } else {
                this.toastService.sendMessage(200, 'A hora inicial não pode ser maior que a final');
              }
            } else {
              this.toastService.sendMessage(200, 'A hora selecionada é inválida');
            }
          } else {
            this.toastService.sendMessage(200, 'Informe a hora final');
          }
        } else {
          this.toastService.sendMessage(200, 'Informe a hora inicial');
        }
      } else {
        this.toastService.sendMessage(200, 'Selecione um dia da semana');
      }
    } else {
      this.toastService.sendMessage(200, 'Informe um título');
    }
  }

  async send() {
    const alert = await this.alertController.create({
      header: 'Confirmação',
      message: 'Você tem certeza que deseja salvar este horário de atendimento?',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
        }, {
          text: 'Sim',
          handler: async () => {
            this.data.services = this.sendServices;
            this.data.payment_early = this.payment_early;
            this.data.on_holidays = this.on_holidays;

            const loading = await this.loadingController.create({
              message: 'Aguarde...',
              spinner: LOADING_ICON
            });

            await loading.present();
            this.loading = true;

            this.storage.get(TOKEN).then((token) => {
              if (this.data.id > 0) {
                this.update(loading, token);
              } else {
                this.create(loading, token);
              }
            });
          }
        }
      ]
    });

    await alert.present();
  }

  create(loading: any, token: string) {
    this.serviceTimeline.saveSchedule(this.data, token).subscribe(async (res: any) => {
      loading.dismiss();
      this.loading = false;
      this.toastService.sendMessage(res.status, res.body.message);

      if (res.status === 201) {
        this.hide();
      }
    }, err => {
      loading.dismiss();
      this.loading = false;
      this.toastService.sendMessage(err.status, err.error.message);
    });
  }

  update(loading: any, token: string) {
    this.serviceTimeline.updateSchedule(this.data, token).subscribe(async (res: any) => {
      loading.dismiss();
      this.loading = false;
      this.toastService.sendMessage(res.status, res.body.message);

      if (res.status === 201) {
        this.hide();
      }
    }, err => {
      loading.dismiss();
      this.loading = false;
      this.toastService.sendMessage(err.status, err.error.message);
    });
  }

  changeCategories() {
    this.sendServices = [];
    let exist = false;

    this.services.forEach(element => {
      this.selectedServices.forEach(element2 => {
        if (element.id === element2) {
          exist = true;
        }
      });
      if (exist) {
        this.sendServices.push(element);
        exist = false;
      }
    });
  }

  setPrice() {
    this.data.services.forEach(element => {
      this.sendServices.forEach(element2 => {
        if (element.service === element2.id) {
          element2.price = element.price;

          if (element.promotion_price) {
            element2.promotion_price = element.promotion_price;
          }
        }
      });
    });
  }

  changeType(event: any) {
    this.services = [];
    this.selectedServices = [];
    this.sendServices = [];
    this.services = this.tempServices;
  }

  close() {
    this.modalController.dismiss();
  }

  hide() {
    this.storage.remove(PULSE_SCHEDULES);
    this.storage.set(HIDE_PULSE_SCHEDULES, true);
    this.modalController.dismiss(true);
  }

}
